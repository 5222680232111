<route>
{
  "meta": {
    "auth": "storeRoleManage"
  }
}
</route>

<template>
  <div>
    <bm-cardTabList :list="tabList" current="收款人"></bm-cardTabList>
    <div class="tableContainer">
      <div class="header" style="margin: 18px 0">
        <span class="title">收款人列表</span>
        <el-button style="float: right" v-auth="'storeRoleAdd'" type="success" @click="handleAddClick">添加</el-button>
      </div>
      <el-table :data="tableData" style="width: 100%" v-loading="loading">
        <el-table-column align="center" prop="accountName" label="账户名称"> </el-table-column>
        <el-table-column align="center" prop="roleDescribe" label="收款信息">
          <template slot-scope="scope">
            <div v-if="scope.row.collectionType === 1">
              {{ scope.row.bankName }} {{ scope.row.encTrueName }}
              <div>{{ scope.row.encBankNo }}</div>
            </div>
            <div v-else>
              <div class="wxInfo">
                <img class="avatar" :src="scope.row.wxAvatarUrl" />
                {{ scope.row.wxNickname }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="roleDescribe" label="收款类型">
          <template slot-scope="scope">
            {{ scope.row.collectionType === 0 ? '微信零钱' : '银行卡' }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button v-auth="'storeRoleEdit'" type="text" size="small" @click="handleCheck(scope)">查看</el-button>
            <el-button v-auth="'storeRoleEdit'" type="text" size="small" @click="handleEdit(scope)"
              >编辑账户名称</el-button
            >
            <el-button
              v-auth="'storeRoleDelete'"
              type="text"
              size="small"
              class="deleteBtnText"
              @click="showDelete(scope)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-pagination
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          class="pagination"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog :close-on-click-modal="false" :title="title" :visible.sync="dialog.show" width="40%" :show-close="false">
      <el-form ref="form" label-position="right" :rules="rules" :model="form" label-width="130px" class="form">
        <el-form-item label="收款账号" prop="accountName">
          <el-input :disabled="dialog.type === 'check'" v-model="form.accountName"></el-input>
        </el-form-item>

        <el-form-item label="收款类型" prop="collectionType">
          <el-radio
            :disabled="dialog.type === 'check' || dialog.type === 'edit'"
            v-model="form.collectionType"
            :label="0"
            @change="handleTypeChange"
            >微信零钱</el-radio
          >

          <!-- :disabled="dialog.type === 'check' || dialog.type === 'edit'" -->
          <el-radio v-model="form.collectionType" :label="1" @change="handleTypeChange">银行卡</el-radio>
        </el-form-item>
        <template v-if="form.collectionType === 0">
          <el-form-item label="手机号" prop="mobilePhone">
            <el-input
              :disabled="dialog.type === 'check' || dialog.type === 'edit'"
              @input="onPhoneChange"
              v-model="form.mobilePhone"
            ></el-input>
          </el-form-item>

          <el-form-item v-if="form.wxOpenId" label="微信">
            <div class="wxInfoContainer">
              <img :src="form.wxAvatarUrl" class="avatar" />
              <span> {{ form.wxNickname }} </span>
            </div>
          </el-form-item>
          <el-form-item label="真实姓名" prop="reUserName">
            <el-input
              :disabled="dialog.type === 'check' || dialog.type === 'edit'"
              v-model="form.reUserName"
            ></el-input>
          </el-form-item>
        </template>
        <template v-else>
          <el-form-item label="手机号" prop="mobilePhone">
            <el-input
              :disabled="dialog.type === 'check' || dialog.type === 'edit'"
              v-model="form.mobilePhone"
            ></el-input>
          </el-form-item>
          <el-form-item label="开户银行" prop="bankName">
            <el-select
              :disabled="dialog.type === 'check'"
              allow-create
              filterable
              default-first-option
              class="searchInput"
              v-model="form.bankCode"
              @change="handleBankSelect"
            >
              <el-option v-for="i in bankList" :key="i.bankCode" :label="i.bankName" :value="i.bankCode"> </el-option>
            </el-select>
            <bm-tip>目前企业付款到银行卡仅支持17家银行，请选择其中之一进行收款</bm-tip>
          </el-form-item>
          <el-form-item label="收款方用户名" prop="encTrueName">
            <el-input
              :disabled="dialog.type === 'check' || dialog.type === 'edit'"
              v-model="form.encTrueName"
            ></el-input>
          </el-form-item>
          <el-form-item label="收款方银行卡号" prop="encBankNo">
            <el-input :disabled="dialog.type === 'check' || dialog.type === 'edit'" v-model="form.encBankNo"></el-input>
          </el-form-item>
        </template>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialog.show = false">取 消</el-button>
        <el-button v-if="dialog.type !== 'check'" type="primary" @click="handleSave">确 定</el-button>
      </span>
    </el-dialog>

    <bm-deleteItem
      url="/boom-center-product-service/sysAdmin/businessAccount/"
      :timestamp="timestamp"
      @successDelete="successDelete"
    ></bm-deleteItem>
  </div>
</template>

<script>
import { pagination } from '@/mixin/pagination'
import { deleteRowItem } from '@/mixin/deleteRowItem'
import bankList from './__com__/bankList'
import { tabListIncludeAccount, tabListExcludeAccount } from '../tabList'
export default {
  mixins: [pagination, deleteRowItem],
  data() {
    return {
      rules: [],
      tabList: [],
      form: {
        accountName: '',
        bankCode: '',
        bankName: '',
        businessId: 0,
        collectionType: 0,
        encBankNo: '',
        encTrueName: '',
        id: 0,
        mobilePhone: '',
        reUserName: '',
        wxAvatarUrl: '',
        wxNickname: '',
        wxOpenId: '18813660577'
      },
      searchValue: {
        page: 1,
        size: 10
      },
      dialog: {
        show: false,
        type: 'add'
      },
      bankList,
      tableData: [],
      timestamp: 0,
      rules: {
        accountName: [{ required: true, message: '请输账号名称', trigger: 'blur' }],
        mobilePhone: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
        reUserName: [{ required: true, message: '请输入真实姓名', trigger: 'blur' }],
        bankName: [{ required: true, message: '请选择开户银行', trigger: 'blur' }],
        encTrueName: [{ required: false, message: '请输入收款方用户名', trigger: 'blur' }],
        encBankNo: [{ required: false, message: '请输入收款方银行卡号', trigger: 'blur' }]
      }
    }
  },
  computed: {
    title() {
      if (this.dialog.type === 'check') {
        return '查看收款人'
      } else if (this.dialog.type === 'edit') {
        return '编辑收款人'
      } else {
        return '新增收款人'
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.$store.getters['vendor/params/getBusinessModel'] == 0) {
        vm.tabList = tabListIncludeAccount
      } else {
        vm.tabList = tabListExcludeAccount
      }
    })
  },
  methods: {
    handleBankSelect(code) {
      console.log(code)
      let item = this.bankList.find(item => item.bankCode === code)
      this.form.bankName = item && item.bankName
      console.log(this.form)
    },
    onPhoneChange(val) {
      this.form.wxOpenId = ''
      if (val.length === 11) {
        this.$http
          .get(`/boom-center-user-service/sysAdmin/members/getMemberInfoByPurePhoneNumber/${val}`)
          .then(res => {
            this.form.wxAvatarUrl = res.avatarUrl
            this.form.wxNickname = res.wxNickname
            this.form.wxOpenId = res.wxOpenId
          })
          .catch(err => {
            this.$message.error('该号码不存在，请先登录小程序')
          })
      }
    },
    handleTypeChange(val) {
      if (val) {
        this.rules.bankName[0].required = true
        this.rules.encTrueName[0].required = true
        this.rules.encBankNo[0].required = true
        this.rules.encBankNo[0].reUserName = false
      } else {
        this.rules.bankName[0].required = false
        this.rules.encTrueName[0].required = false
        this.rules.encBankNo[0].required = false
        this.rules.encBankNo[0].reUserName = true
      }
    },
    // 获取角色列表
    getList(val) {
      this.loading = true
      this.searchValue.page = val === true ? this.currentPage : 1
      this.searchValue.size = this.pageSize

      this.$http
        .get('/boom-center-product-service/sysAdmin/businessAccount/page', {
          params: { businessId: this.$store.getters['vendor/params/getVendorId'], ...this.searchValue }
        })
        .then(res => {
          this.tableData = res.list.map(item => {
            item.bankCode = +item.bankCode
            return item
          })
          this.total = parseInt(res.total)
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          this.loading = false
        })
    },
    getDetail(id) {
      this.$http
        .get('/boom-center-product-service/sysAdmin/businessAccount/' + id)
        .then(res => {
          this.form = res
        })
        .catch(err => {
          this.$message.error('操作错误')
        })
    },
    // 点击编辑
    handleEdit(scope) {
      this.getDetail(scope.row.id)

      this.dialog = { show: true, type: 'edit' }
    },

    handleCheck(scope) {
      this.getDetail(scope.row.id)
      this.dialog = { show: true, type: 'check' }
    },
    // 点击添加按钮
    handleAddClick() {
      this.dialog = { show: true, type: 'add' }
      this.form = {
        accountName: '',
        bankCode: '',
        bankName: '',
        businessId: 0,
        collectionType: 0,
        encBankNo: '',
        encTrueName: '',
        id: 0,
        mobilePhone: '',
        reUserName: '',
        wxAvatarUrl: '',
        wxNickname: '',
        wxOpenId: ''
      }
    },

    // 添加或修改角色
    handleSave() {
      this.form.businessId = this.$store.getters['vendor/params/getVendorId']
      this.$refs['form'].validate((valid, validObj) => {
        if (!valid) {
          this.$message.error(validObj[Object.keys(validObj)[0]][0].message)
          return true
        }
        if (this.dialog.type === 'edit') {
          const { businessId, ...payload } = this.form
          this.editRoles('/boom-center-product-service/sysAdmin/businessAccount', payload)
        } else {
          const { id, ...payload } = this.form
          this.editRoles('/boom-center-product-service/sysAdmin/businessAccount', payload)
        }
      })
    },
    editRoles(url, params) {
      if (this.form.collectionType === 0 && this.form.wxAvatarUrl === '') {
        this.$message.error('微信零钱需要有登录我们小程序')
        return
      }
      const loading = this.$loading({
        lock: true,
        text: '正在提交，请稍候',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      if (this.dialog.type === 'edit') {
        this.$http
          .put(url, params)
          .then(res => {
            this.dialog.show = false
            this.getList()
          })
          .catch(err => {
            this.$message.error(err.msg || '操作失败')
          })
          .finally(() => {
            loading.close()
          })
      } else {
        this.$http
          .post(url, params)
          .then(res => {
            this.dialog.show = false
            this.getList()
          })
          .catch(err => {
            this.$message.error(err.msg || '操作失败')
          })
          .finally(() => {
            loading.close()
          })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.tableContainer {
  background-color: #fff;
  padding: @container-margin;
}
.wxInfoContainer {
  display: flex;
  align-items: center;
  .avatar {
    .rounded(40px);
    margin: 0 15px;
  }
}
.wxInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  .avatar {
    .rounded(40px);
    margin: 0 15px;
  }
}
</style>
