export default [
  {
    bankName: '工商银行',
    bankCode: 1002
  },
  {
    bankName: '农业银行',
    bankCode: 1005
  },
  {
    bankName: '建设银行',
    bankCode: 1003
  },
  {
    bankName: '中国银行',
    bankCode: 1026
  },
  {
    bankName: '交通银行',
    bankCode: 1020
  },
  {
    bankName: '招商银行',
    bankCode: 1001
  },
  {
    bankName: '邮储银行',
    bankCode: 1066
  },
  {
    bankName: '民生银行',
    bankCode: 1006
  },
  {
    bankName: '平安银行',
    bankCode: 1010
  },
  {
    bankName: '中信银行',
    bankCode: 1021
  },
  {
    bankName: '浦发银行',
    bankCode: 1004
  },
  {
    bankName: '兴业银行',
    bankCode: 1009
  },
  {
    bankName: '光大银行',
    bankCode: 1022
  },
  {
    bankName: '广发银行',
    bankCode: 1027
  },
  {
    bankName: '华夏银行',
    bankCode: 1025
  },
  {
    bankName: '宁波银行',
    bankCode: 1056
  },
  {
    bankName: '北京银行',
    bankCode: 4836
  },
  {
    bankName: '上海银行',
    bankCode: 1024
  },
  {
    bankName: '南京银行',
    bankCode: 1054
  },
  {
    bankName: '长子县融汇村镇银行',
    bankCode: 4755
  },
  {
    bankName: '长沙银行',
    bankCode: 4216
  },
  {
    bankName: '浙江泰隆商业银行',
    bankCode: 4051
  },
  {
    bankName: '中原银行',
    bankCode: 4753
  },
  {
    bankName: '企业银行（中国）',
    bankCode: 4761
  },
  {
    bankName: '顺德农商银行',
    bankCode: 4036
  },
  {
    bankName: '衡水银行',
    bankCode: 4752
  },
  {
    bankName: '长治银行',
    bankCode: 4756
  },
  {
    bankName: '大同银行',
    bankCode: 4767
  },
  {
    bankName: '河南省农村信用社',
    bankCode: 4115
  },
  {
    bankName: '宁夏黄河农村商业银行',
    bankCode: 4150
  },
  {
    bankName: '山西省农村信用社',
    bankCode: 4156
  },
  {
    bankName: '安徽省农村信用社',
    bankCode: 4166
  },
  {
    bankName: '甘肃省农村信用社',
    bankCode: 4157
  },
  {
    bankName: '天津农村商业银行',
    bankCode: 4153
  },
  {
    bankName: '广西壮族自治区农村信用社',
    bankCode: 4113
  },
  {
    bankName: '陕西省农村信用社',
    bankCode: 4108
  },
  {
    bankName: '深圳农村商业银行',
    bankCode: 4076
  },
  {
    bankName: '宁波鄞州农村商业银行',
    bankCode: 4052
  },
  {
    bankName: '浙江省农村信用社联合社',
    bankCode: 4764
  },
  {
    bankName: '江苏省农村信用社联合社',
    bankCode: 4217
  },
  {
    bankName: '江苏紫金农村商业银行股份有限公司',
    bankCode: 4072
  },
  {
    bankName: '北京中关村银行股份有限公司',
    bankCode: 4769
  },
  {
    bankName: '星展银行（中国）有限公司',
    bankCode: 4778
  },
  {
    bankName: '枣庄银行股份有限公司',
    bankCode: 4766
  },
  {
    bankName: '海口联合农村商业银行股份有限公司',
    bankCode: 4758
  },
  {
    bankName: '南洋商业银行（中国）有限公司',
    bankCode: 4763
  }
]
